<template lang="pug">
  .row.market-header
    .col-12
      .market-block-settings
        .market-block-settings__row
          .market-block-settings__select

            select-box(
              v-show="false",
              tabIndex="1",
              :items="cryptos",
              name="CryptoSelect",
              v-model.number="filters.crypto_currency_id"
            )

            select-box(
              v-if="isMyAds"
              tabIndex="1",
              :items="countries",
              name="CountrySelect",
              :show-icon="false",
              v-model.number="filters.country_id",
              :show-label="true",
              styleWidth="16",
            )

            select-box(
              tabIndex="2",
              :items="getFiats",
              name="FiatSelect",
              v-model.number="filters.currency_id",
            )

            select-box(
              tabIndex="3",
              :items="payments",
              name="PaymentSelect",
              v-model.number="selectedPayment",
              styleWidth="33",
            )

          .desktop-extra-options
            a.accordion-toggle(:class="isOpenOptions ? 'active' : ''", @click="toggleOpenOptions") Дополнительные параметры

        .market-block-settings__row.mt-4(v-if="isOpenOptions")
          .market-block-settings__select
            input-number(
              title="Цена от",
              name="'Цена от'",
              v-model.number="filters.price_from",
              :label="currencyCode",
              :step="inputStep",
              v-validate="'decimal:2|min_value:0'"
            )

            input-number(
              title="Цена до",
              name="'Цена до'",
              v-model.number="filters.price_to",
              :label="currencyCode",
              :step="inputStep",
              v-validate="'decimal:2|min_value:0'"
            )

            input-number(
              title="Мин сумма сделки",
              name="'Мин сумма сделки'",
              v-model.number="filters.min_from",
              :label="currencyCode",
              :step="inputStep",
              v-validate="'decimal:2|min_value:0'"
            )

            input-number(
              title="Макс сумма сделки",
              name="'Макс сумма сделки'",
              v-model.number="filters.max_to",
              :label="currencyCode",
              :step="inputStep",
              v-validate="'decimal:2|min_value:0'"
            )

          .block-range-marketPage
            input-range100(:title="labelRateRenge", v-model.number="filters.reputation_from")

        .market-block-settings__row.mt-4(v-if="isOpenOptions && errors.items.length")
          ul
            li.errors(v-for="error in errors.all()") {{ error }}
</template>

<script>
import InputNumber from './Partials/InputNumber';
import InputRange100 from './Partials/InputRange100';
import SelectBox from './Partials/SelectBox';
import { mapGetters } from 'vuex';
import { REQUEST_CRYPTO, REQUEST_CURRENCIES, REQUEST_PAYMENTS, REQUEST_BANKS, REQUEST_COUNTRIES } from '../../store/actions/ad';
import { ADS_FILTER_ALL_PAYMENTS, ADS_PAYMENT_SYSTEMS } from '../../store/actions/markets';
import _ from 'lodash';
import { differenceObject } from './Mixins/differenceObject';
import analytics from '@/components/mixins/analytics';
import { DASHBOARD_ADS_FILTER_SET } from "../../store/actions/dashboard";
import cfg from '../../../config';

export default {
  name: 'Filters',
  components: {
    InputNumber,
    InputRange100,
    SelectBox,
  },

  mixins: [analytics],

  props: {
    isSellTab: {
      type: Boolean,
      default: true,
    },
    filterSource: {
      type: Object,
      default() {
        return {};
      },
    },
    filterAction: {
      type: String,
      default: 'must define [filterAction] in child component',
    },
  },

  data() {
    return {
      inputStep: 0.01,
      defaultCryptoIcon: cfg.default.defaultCryptoIcon,
      defaultFiatIcon: cfg.default.defaultFiatIcon,
      defaultPaymentIcon: cfg.default.defaultPaymentIcon,
      defaultCurrencyCode: 'USD',
      debounceTime: 500,
      isOpenOptions: false,
      cryptos: [],
      countries: [],
      fiats: [],
      paymentsAll: [],
      banksAll: [],
      selectedPayment: 0,
      currentCurrency: null,
      currentCountry: null,
      // todo mirrors market api request filters, may give problems in future
      filters: {
        page: 1,
        currency_id: this.$store.state.markets.filter.currency_id || 1,
        crypto_currency_id: this.$store.state.markets.filter.crypto_currency_id || 1,
        payment_system_id: this.$store.state.markets.filter.payment_system_id || 0,
        bank_id: this.$store.state.markets.filter.bank_id || null,
        price_from: 0.00,
        price_to: 0.00,
        min_from: 0.00,
        max_to: 0.00,
        reputation_from: 0,
        country_id: parseInt(localStorage.getItem('selected-country')) || null,
      },
      // todo global lang files
      lang: {
        rangeLabelSeller: 'Рейтинг продавца',
        rangeLabelBuyer: 'Рейтинг покупателя',
      },
    };
  },

  computed: {
    ...mapGetters([
      'isAuthenticated',
      'recommendedPayments',
      'allPaymentsFilter',
    ]),

    // filtered and prepared payments array
    payments() {
      let formattedPayments = [];
      if(this.allPaymentsFilter){
        formattedPayments = this.getPaymentsForMyAds;
      } else {
        formattedPayments = this.getPaymentsForMarket;
      }

      return [
        {
          value: 0,
          ads: 0,
          payment_system_id: 0,
          bank_id: 0,
          short_title: 'Платежные методы',
        },
        ...formattedPayments,
      ];
    },

    currencyCode() {
      if (this.filters.currency_id && this.fiats) {
        const current = this.fiats.find(fiat => fiat.value === this.filters.currency_id);
        if (current) {
          return current.short_title;
        }
      }

      return this.defaultCurrencyCode;
    },

    labelRateRenge() {
      return this.isSellTab ? this.lang.rangeLabelSeller : this.lang.rangeLabelBuyer;
    },

    isMyAds(){
      return this.filterAction === DASHBOARD_ADS_FILTER_SET;
    },

    getPaymentsForMyAds() {
      return this.paymentsAll.map(item => {
        return {
          value: item.value,
          ads: 0,
          payment_system_id: item.value,
          short_title: item.label,
          icon: item.icon,
        };
      });
    },

    getPaymentsForMarket() {
      return this.recommendedPayments.map(item => {
        let result = {
          value: item.id,
          ads: item.ads,
          payment_system_id: item.payment_system_id,
          bank_id: item.bank_id,
        };

        if (item.bank_id) {
          let filtered = this.banksAll.find(bank => bank.value === item.bank_id);
          if (filtered) {
            let payment_system = this.paymentsAll.find(payment => payment.value === item.payment_system_id);
            result.short_title = `${filtered.label} (${payment_system.label})`;
            result.icon = filtered.icon;
          }
        } else {
          let filtered = this.paymentsAll.find(payment => payment.value === item.payment_system_id);
          if (filtered) {
            result.short_title = filtered.label;
            result.icon = filtered.icon || this.defaultPaymentIcon;
          }
        }

        return result;
      });
    },

    getFiats(){
      if(!this.isMyAds){
        return this.fiats;
      }
      return [
        {
          value: 0,
          label: 'Валюты',
          short_title: 'Валюты',
        },
        ...this.fiats,
      ];
    },
  },

  watch: {
    //
    filters: {
      handler: async function(newFilter) {
        if (newFilter.currency_id !== this.currentCurrency) {
          await this.loadPayments();
          this.currentCurrency = newFilter.currency_id;
        }
        if (newFilter.country_id !== this.currentCountry) {
          await this.loadFiats();
          await this.loadPayments();
          if(this.isMyAds){
            localStorage.setItem('selected-country', newFilter.country_id);
          }
          this.currentCountry = newFilter.country_id;
        }
        this.debouncedEmitFilters();
      },
      deep: true,
    },

    //
    isAuthenticated: {
      handler: function() {
        this.debouncedEmitFilters();
      },
    },

    //
    selectedPayment: function(val) {
      const payment = this.payments.find(item => {
        return item.value === val;
      });

      if (payment) {
        this.filters.payment_system_id = payment.payment_system_id;
        this.filters.bank_id = payment.bank_id;
      } else {
        this.resetPaymentAndBanks();
      }
    },

    //
    isSellTab: async function() {
      await this.loadPayments();
    },

    //
    filterAction: async function() {
      await this.loadPayments();
      await this.loadFiats();
      await this.loadCountries();
    },
  },

  created() {
    this.debouncedEmitFilters = _.debounce(this.emitFilters, this.debounceTime);
  },

  async mounted() {
    await this.loadCryptos();
    await this.loadFiats();
    await this.loadPayments();
    await this.loadCountries();
    this.currentCurrency = this.filters.currency_id;
    this.currentCountry = this.filters.country_id;
  },

  methods: {
    // reset payment filters when 'Все' selected
    resetPaymentAndBanks() {
      this.filters.payment_system_id = '';
      this.filters.bank_id = '';
    },

    //
    resetCurrency() {
      this.filters.currency_id = 0;
    },

    // select payment and band or use default
    trySelectPaymentAndBank() {
      const payment = this.payments.find(
        item => item.payment_system_id === this.filters.payment_system_id && item.bank_id === this.filters.bank_id
      );

      if (payment) {
        this.selectedPayment = payment.id;
      } else {
        this.selectedPayment = 0;
      }
    },
    trySelectCurrency() {
      const currencies = this.getFiats.find(item => {
        return item.value === this.filters.currency_id;
      });
      if(!currencies){
        if(this.isMyAds){
          this.resetCurrency();
        } else {
          this.filters.currency_id = 1;
        }
      }
    },

    //
    emitFilters() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.$store.dispatch(this.filterAction, differenceObject(this.filters, this.filterSource));
        }
      });
    },

    //
    toggleOpenOptions() {
      this.isOpenOptions = !this.isOpenOptions;
      if (this.isOpenOptions) {
        this.sendAnalytics('Filter', 'filter_open');
      } else {
        this.sendAnalytics('Filter', 'filter_hide');
      }
    },

    //
    async loadPayments() {
      this.resetPaymentAndBanks();
      if(this.isMyAds) { // Мои объявления
        this.loadPaymentsForMy();
      } else {
        this.loadPaymentsForMarket();
      }

      this.trySelectCurrency();
      this.trySelectPaymentAndBank();
    },

    //
    loadCryptos() {
      this.$store.dispatch(REQUEST_CRYPTO).then(data => {
        this.cryptos = data;
      });
    },

    //
    async loadFiats() {
      await this.$store.dispatch(REQUEST_CURRENCIES, {
        country_id: this.filters.country_id,
      }).then(data => {
        this.fiats = data;
      });
    },

    //
    loadCountries() {
      this.$store.dispatch(REQUEST_COUNTRIES).then(data => {
        this.setCountry(data);
        this.countries = data;
      });
    },

    //
    async loadPaymentsForMy() {
      this.banksAll = await this.$store.dispatch(REQUEST_BANKS);
      await this.$store.commit(ADS_FILTER_ALL_PAYMENTS, true);
      let payments = await this.$store.dispatch(REQUEST_PAYMENTS, {
        country_id: this.filters.country_id,
        currency_id: this.filters.currency_id
      });
      this.paymentsAll = payments || [];
    },

    //
    async loadPaymentsForMarket() {
      this.paymentsAll = await this.$store.dispatch(REQUEST_PAYMENTS);
      this.banksAll = await this.$store.dispatch(REQUEST_BANKS);
      await this.$store.dispatch(ADS_PAYMENT_SYSTEMS, {
        currency_id: this.filters.currency_id,
        is_sale: this.isSellTab ? 1 : 0
      });
      await this.$store.commit(ADS_FILTER_ALL_PAYMENTS, false);
    },

    //
    setCountry(countries) {
      if (this.isMyAds) {
        let country_id = 1;
        let selected_country = parseInt(localStorage.getItem('selected-country'));

        if (countries && countries.length > 0) {
          country_id = countries[0].value;
        }

        if (selected_country) {
          this.filters.country_id = selected_country;
        } else {
          this.filters.country_id = country_id;
        }
      } else {
        this.filters.country_id = null;
      }
    },
  },

};
</script>

<style scoped>
  .market-block-settings__select {
    justify-content: flex-start;
  }

  .accordion-toggle {
    cursor: pointer;
  }

  .errors {
    color: red;
  }
</style>
