import AdCard from '../AdCard';
import AdCardEdit from '../AdCardEdit';
import {mapGetters} from 'vuex';

export default {
  components: {
    AdCard,
    AdCardEdit,
  },

  data() {
    return {
      action: 'must define [action] in child component: ' + this.$options.name,
      filterAction: 'must define [filterAction] in child component: ' + this.$options.name,
      debounceTime: 300,
      // todo lang files
      lang: {
        adList: {
          nothingFound: 'Пока еще нет объявлений по вашему запросу. Попробуйте изменить условия поиска.'
        }
      }
    };
  },

  computed: {
    ...mapGetters([
      'loading',
    ]),
    // must implement this.filterSource in child component
    filters() {
      return this.filterSource;
    },
    // must implement this.loadingAction in child component
    isLoading(){
      // return this.loading(this.loadingAction) === 'loading';
      return false;
    },
  },

  watch: {
    filters: {
      handler: function() {
        this.updateList();
      },
      deep: true,
    },
  },

  methods: {
    updateList() {
      this.$store.dispatch(this.action, {filter: {}});
    },
  },

  mounted() {
    this.updateList();
  },
};
