<template lang="pug">
  div
    .profile-block-offer(v-if="items.length")
      ad-card(v-for="item in items", :key="item.id", :item="item")

    .ads-market-pagination(v-if="items.length")
      el-pagination(
        v-if='hasPagination',
        :total='pages.total',
        :page-size='pages.per_page',
        :current-page='pages.current_page',
        :disabled='isLoading'
        background,
        layout='prev, pager, next',
        @current-change='changeCurrent',
      )

    div.nothing-found(v-else)
      | {{lang.adList.nothingFound}}

    div(v-else)
</template>

<script>
import {ADS_BUYERS, ADS_FILTER_SET, ADS_RESET_PAGE} from '../../../store/actions/markets';
import { EVENT_MARKET_CLOSE, EVENT_MARKET_OPEN } from '../../../store/actions/events';
import adList from '../Mixins/adList';
import { mapGetters } from 'vuex';
import adUtils from '../../mixins/ads';

export default {
  name: 'AdsBuy',

  mixins: [
    adUtils,
    adList,
  ],

  data() {
    return {
      action: ADS_BUYERS,
      loadingAction: ADS_BUYERS,
      filterAction: ADS_FILTER_SET,
    };
  },

  computed: {
    ...mapGetters({
      itemsRaw: 'buy',
      paginationRaw: 'buyPagination',
      filterSource: 'marketFilter',
      loading: 'loading',
    }),
    isLoading() {
      return this.loading(ADS_BUYERS) === 'loading';
    },
  },

  mounted() {
    this.$store.dispatch(EVENT_MARKET_OPEN);
    this.$emit('handleAnalytics');
  },

  beforeCreate() {
    this.$store.commit(ADS_RESET_PAGE);
  },
  beforeDestroy() {
    this.$store.dispatch(EVENT_MARKET_CLOSE);
  },
};
</script>
