<template lang="pug">
  div
    .card-product-my
      .card-product-my__content
        .card-product-my__row.card-product-my__row-first
          p.card-product-my__row-first-title(:class="{'sell-label': item.is_sale, 'buy-label': !item.is_sale}")
            | {{adLabel}}
          .card-product-my__row-first-control
            router-link.card-product__icon-editing(:to="{name: 'AdEdit', params: { id: item.id, step: 'insert' }}", tag="span")
            span.card-product__icon-close(@click="deleteAd(item)")

        .card-product-my__row.card-product-my__row-second
          .card-product__switch
            p Статус
            .onoffswitch
              input.onoffswitch-checkbox(
                type='checkbox',
                name='onoffswitch',
                :disabled='isLoading'
                :checked='is_active',
                :id="'switch-' + item.id",
                :ref="'switch-' + item.id",
                @change="toggleStatus(item)"
              )
              label.onoffswitch-label(:for="'switch-' + item.id" :class="{active: is_active}")
                span.onoffswitch-inner
                span.onoffswitch-switch

          .card-product-my__paymentMethods
            p.card-product-my__paymentMethods-title Методы оплаты
            .card-product-my__row-payment
              .card-product__icon-bank(v-for="(payment, index) in payments", :key="index")
                span.card-product__icon-payment(:style="iconBg(payment.icon)", :title="payment.title")

              span.card-product__icon-bank(v-show="hasManyPayments", title="показать все", v-popover:popoverPayments)
                span.card-product__icon-payment-more
                  | +
              el-popover(
                placement="top",
                width="auto",
                trigger="hover",
                ref="popoverPayments",
              )
                .card-product-my__paymentMethods.max-width
                  .card-product-my__row-payment
                      span.card-product__icon-bank(v-for="(payment, index) in item.payments.slice(1)", :key="index")
                        span.card-product__icon-payment(:style="iconBg(payment.icon)", :title="payment.title")

        .card-product-my__row.card-product-my__row-third
          .card-product-new__price
            p от {{item.min}} до {{item.max}} {{item.currency.code}}
            span {{item.price}} {{item.currency.code}}

          .card-product-my__row-cryptocurrency
            p Криптовалюта
            .card-product__cryptocurrency-block
              span.card-product__cryptocurrency-block-bitcoin(
                :style="iconBg(item.crypto_currency.icon)",
                :title="item.crypto_currency.title"
              )
</template>

<script>
import card from './Mixins/card';
import { AD_DELETE, AD_ACTIVATE, AD_DEACTIVATE } from '@/store/actions/ad';
import analytics from '@/components/mixins/analytics';
import {mapGetters} from "vuex";

export default {
  name: 'AdCardEdit',

  mixins: [
    card,
    analytics,
  ],

  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      MAX_PAYMENTS_VISIBLE: 1,
      is_active: this.item.is_active
    };
  },

  computed: {
    ...mapGetters(['loading']),
    adLabel() {
      return this.item.is_sale ? 'Продаю' : 'Покупаю';
    },
    isLoading() {
      return this.loading(AD_ACTIVATE) === 'loading' || this.loading(AD_DEACTIVATE) === 'loading' || this.loading('DASHBOARD_ADS') === 'loading';
    },
  },
  watch: {
    'item.is_active'(val) {
      this.is_active = val
    }
  },

  methods: {
    //
    async deleteAd(item) {
      try {
        const confirm = await this.$confirm('Вы уверены что хотите удалить объявление', 'Внимание', {
          confirmButtonText: 'Да',
          cancelButtonText: 'Нет',
          type: 'warning',
        });

        if (confirm) {
          await this.$store.dispatch(AD_DELETE, item.id);
          this.$emit('updateList')
          this.sendAnalytics('Ad', 'ad_delete', false, ['yandex']);
        }
      } catch (cancel) {}
    },

    //
    toggleStatus(item) {
      this.is_active ? this.deactivateAd(item.id) : this.activateAd(item.id)
    },

    //
    async activateAd (id) {
      try {
        const result = await this.$store.dispatch(AD_ACTIVATE, {id});

        if (result) {
          this.is_active = result.data.is_active
          this.$message({
            message: 'Объявление успешно включено!',
            type: 'success',
          });
          this.sendAnalytics('Ad', 'ad_pause_off', false, ['yandex']);
          // todo update ad
        }
      } catch (cancel) {
        this.$refs[`switch-${this.item.id}`].checked = false;
        let errors = '';
        if (cancel.errors) {
          Object.keys(cancel.errors).forEach((error) => {
            errors += `<li class="switch-error">${cancel.errors[error]}</li>`;
          });
        } else {
          errors += `<li class="switch-error">${cancel.message}</li>`;
        }
        this.$message({
          dangerouslyUseHTMLString: true,
          message: '' +
            '<p class="error-title">Не удалось включить объявление.</p>' +
            '<ul class="error-list">' +
            errors +
            '</ul>',
          type: 'error',
        });
      }
    },

    //
    async deactivateAd (id) {
      try {
        const result = await this.$store.dispatch(AD_DEACTIVATE, {id});

        if (result) {
          this.is_active = result.data.is_active
          this.$message({
            message: 'Объявление успешно выключено!',
            type: 'success',
          });
          this.sendAnalytics('Ad', 'ad_pause_on', false, ['yandex']);
          // todo update ad
        }
      } catch (cancel) {
        this.$refs[`switch-${this.item.id}`].checked = true;
        let errors = '';
        if (cancel.errors) {
          Object.keys(cancel.errors).forEach((error) => {
            errors += `<li class="switch-error">${cancel.errors[error]}</li>`;
          });
        } else {
          errors += `<li class="switch-error">${cancel.message}</li>`;
        }
        this.$message({
          dangerouslyUseHTMLString: true,
          message: '' +
            '<p class="error-title">Не удалось выключить объявление.</p>' +
            '<ul class="error-list">' +
            errors +
            '</ul>',
          type: 'error',
        });
      }

    },
  }
};
</script>

<style>
  .card-product__icon-editing, .card-product__icon-close {
    margin-left: 13px;
    cursor: pointer;
  }

  .card-product__icon-payment {
    width: 30px;
    height: 30px;
  }

  .card-product-my__paymentMethods .max-width {
    width: 100%;
    max-width: 100%;
  }

  .error-title {
    text-align: center;
    font-weight: 500;
    margin-top: 5px !important;
  }
  .error-list {
    margin-top: 10px;
    margin-bottom: 0;
    list-style: none;
    padding-left: 0;
  }
  .error-list .switch-error {
    margin-bottom: 5px;
  }
  .sell-label{
    color: #FF2D55;
  }
  .buy-label{
    color: #2464EB;
  }
</style>
