<template lang="pug">
  .main-content.layout-main__centering.container.pb-5
    .tab.tab_market-page
      router-link.tablinks(
        v-if="isAllAdsTab"
        :class="isSellTab ? 'active' : ''",
        tag="span",
        :to="{name:'MarketSell'}",
        v-loading='isMarketSellersLoading',
        element-loading-text='Загрузка данных...',
      ) Покупка

      router-link.tablinks(
        v-if="isAllAdsTab"
        :class="isBuyTab ? 'active' : ''",
        tag="span",
        :to="{name:'MarketBuy'}",
        v-loading='isMarketBuyersLoading',
        element-loading-text='Загрузка данных...',
      ) Продажа

      router-link.tablinks.right-tabs(
        v-if="isAuthenticated"
        :class="isMyTab ? 'active' : ''",
        tag="span",
        :to="{name:'MarketMy'}",
        v-loading='isMyLoading',
        element-loading-text='Загрузка данных...',
      ) Мои объявления

      router-link.tablinks.right-tabs(
        v-if="isAuthenticated"
        :class="isAllAdsTab ? 'active' : ''",
        tag="span",
        :to="{name:'MarketSell'}"
      ) Все объявления


    .tabcontent
      .tabbedblocks-content.container
        Filters(:isSellTab="isSellTab", :filterSource="currentFilter", :filterAction="currentFilterAction")

    .tabcontent
      .tabbedblocks-content.container
        .row.market-content
          .col-12
            component(v-bind:is="currentTabComponent" @handleAnalytics="handleAnalytics()")
</template>

<script>
import Filters from '../components/Market/Filters';
import AdsSell from '../components/Market/Lists/AdsSell';
import AdsBuy from '../components/Market/Lists/AdsBuy';
import { mapGetters } from 'vuex';
import {ADS_BUYERS, ADS_FILTER_SET, ADS_SELLERS} from '../store/actions/markets';
import {DASHBOARD_ADS, DASHBOARD_ADS_FILTER_SET} from '../store/actions/dashboard';
import {PROFILE_SET_PARTNER_ID} from "../store/actions/user";
import analytics from '@/components/mixins/analytics';
import MyAdsAll from "../components/Market/Lists/MyAdsAll";

export default {

  components: {
    Filters,
    AdsSell,
    AdsBuy,
    MyAdsAll,
  },

  mixins: [
    analytics,
  ],

  props: {
    isSellTab: {
      type: Boolean,
      default: true,
    },
    isMyTab: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters([
      'marketFilter',
      'dashboardFilter',
      'loading',
      'isAuthenticated',
    ]),

    isBuyTab() {
      return !this.isSellTab;
    },

    isAllAdsTab() {
      return !this.isMyTab;
    },

    isMyLoading() {
      return this.loading(DASHBOARD_ADS) === 'loading';
    },

    isMarketSellersLoading() {
      return this.loading(ADS_SELLERS) === 'loading';
    },

    isMarketBuyersLoading() {
      return this.loading(ADS_BUYERS) === 'loading';
    },

    currentTabComponent() {
      if(this.isMyTab){
        return  'my-ads-all';
      }
      return this.isSellTab ? 'ads-sell' : 'ads-buy'
    },

    currentFilter() {
      return this.isMyTab
        ? this.dashboardFilter
        : this.marketFilter;
    },

    currentFilterAction() {
      return this.isMyTab
        ? DASHBOARD_ADS_FILTER_SET
        : ADS_FILTER_SET;
    },
  },

  mounted() {
    if (this.$route.params.partner_id) {
      this.$store.commit(PROFILE_SET_PARTNER_ID, this.$route.params.partner_id)
    }
  },

  methods: {
    handleAnalytics() {
      this.sendAnalytics('Market', this.currentTabComponent, false);
    }
  },
};
</script>

<style scoped>
  .tabcontent {
    display: block;
  }

  .right-tabs {
    float: right;
  }

  .tab_market-page .tablinks {
    cursor: pointer;
    color: $blue600;
  }
</style>
