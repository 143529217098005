<template lang="pug">
  div(
  v-loading="isLoading"
  element-loading-text="Загрузка..."
  )
    .profile-block-offer(v-if="items.length")
      ad-card-edit(v-for="item in items", :key="item.id", :item="item" @updateList="updateList")

    div.nothing-found(v-else-if="!isLoading")
      | {{lang.adList.nothingFound}}
</template>

<script>
  import adList from '../Mixins/adList';
  import {DASHBOARD_ADS_ALL, DASHBOARD_ADS, DASHBOARD_ADS_FILTER_SET} from '../../../store/actions/dashboard';
  import { mapGetters } from 'vuex';
  import adUtils from '../../mixins/ads';

  export default {
    name: 'MyAdsAll',

    mixins: [
      adUtils,
      adList,
    ],

    data() {
      return {
        action: DASHBOARD_ADS_ALL,
        loadingAction: DASHBOARD_ADS,
        filterAction: DASHBOARD_ADS_FILTER_SET,
      };
    },

    computed: {
      ...mapGetters({
        itemsRaw: 'allDashboard',
        filterSource: 'dashboardFilter',
      }),
    },
    mounted() {
      this.$emit('handleAnalytics');
    },
  };
</script>

<style scoped>

</style>
